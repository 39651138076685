.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

#table-wrapper {
  position: relative;
}
#table-scroll {
  overflow: auto;
  margin-top: 20px;
}
