.camera {
  position: relative;
}

#snap {
  /* position: absolute; */
  bottom: 20px;
  left: 20px;
  appearance: none;
  border: none;
  outline: none;
  padding: 8px 16px;
  background-image: linear-gradient(to right, #844fff 50%, #ff4f84 50%);
  background-position: 0%;
  background-size: 200%;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  transition: 0.4s;
  cursor: pointer;
}

#snap:hover {
  background-position: 100%;
}

#close {
  position: absolute;
  bottom: 20px;
  left: 20px;
  appearance: none;
  border: none;
  outline: none;
  padding: 8px 16px;
  background-image: linear-gradient(to right, #844fff 50%, #ff4f84 50%);
  background-position: 0%;
  background-size: 200%;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  transition: 0.4s;
  cursor: pointer;
}

#close:hover {
  background-position: 100%;
}

#ready {
  position: absolute;
  bottom: 20px;
  right: 20px;
  appearance: none;
  border: none;
  outline: none;
  padding: 8px 16px;
  background-image: linear-gradient(to right, #844fff 50%, #ff4f84 50%);
  background-position: 0%;
  background-size: 200%;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  transition: 0.4s;
  cursor: pointer;
}

#ready:hover {
  background-position: 100%;
}

.result {
  position: fixed;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  transition: 0.4s;
}

.result.hasPhoto {
  left: 0;
}

canvas {
  width: 100%;
  height: auto;
}
