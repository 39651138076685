@import '../node_modules/prismjs/themes/prism-tomorrow';
@import './assets/scss/app.scss';
[dir]
  .vx-wizard.nav.nav-tabs
  .step-wrapper.nav-item
  .step.nav-link.done
  .step-text {
  color: #fe810b;
}

@mixin mobile {
  @media screen and (max-width: 480px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: 768px) {
    @content;
  }
}
@mixin laptop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

.handle-draggable{
  cursor: move;
}


.messages {
  background-color: #ddddf7;
  height: 80vh;
  overflow: scroll;
  padding-top: 5px;

  .message {
    display: flex;
    gap: 5px;
    margin-bottom: 10px;

    .messageInfo {
      display: flex;
      flex-direction: column;
      color: gray;
      font-weight: 300;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .messageContent {
      max-width: 80%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      div {
        background-color: white;
        padding: 10px 20px;
        border-radius: 0px 10px 10px 10px;
        max-width: max-content;
        p{
          font-size: 18px;
        }
      }

      img {
        width: 50%;
      }
    }

    &.owner {
      flex-direction: row-reverse;

      .messageContent {
        align-items: flex-end;
        padding-right: 5px;
        div {
          background-color: #8da4f1;
          color: white;
          border-radius: 10px 0px 10px 10px;
          p{
            font-size: 18px;
          }
          b{
            color: black;
          }
        }
      }
    }
  }
}


.chat {
  flex: 2;

  .chatInfo {
    height: 50px;
    background-color: #5d5b8d;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    color: lightgray;
  }

  .chatIcons {
    display: flex;
    gap: 10px;

    img {
      height: 24px;
      cursor: pointer;
    }
  }

  

  .input {
    height: 50px;
    background-color: white;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      width: 100%;
      border: none;
      outline: none;
      color: #2f2d52;
      font-size: 18px;

      &::placeholder {
        color: lightgray;
      }
    }

    .send {
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        height: 24px;
        cursor: pointer;
      }

      button {
        border: none;
        padding: 10px 15px;
        color: white;
        background-color: #8da4f1;
        cursor: pointer;
      }
    }
  }
}

.home {
  // background-color: #a7bcff;
  // height: 100vh;
  // display: flex;
  // align-items: center;
  // justify-content: center;

  .container {
    border: 1px solid white;
    border-radius: 10px;
    // width: 65%;
    // height: 80%;
    display: flex;
    overflow: hidden;
    @include tablet {
     width: 90%;
    }

    .sidebar {
      flex: 1;
      background-color: #3e3c61;
      position: relative;

      .navbar {
        display: flex;
        align-items: center;
        background-color: #2f2d52;
        height: 50px;
        padding: 10px;
        justify-content: space-between;
        color: #ddddf7;

        .logo {
          font-weight: bold;
          @include tablet {
            display: none;
          }
        }

        .user {
          display: flex;
          gap: 10px;

          img {
            background-color: #ddddf7;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            object-fit: cover;
          }

          button {
            background-color: #5d5b8d;
            color: #ddddf7;
            font-size: 10px;
            border: none;
            cursor: pointer;
            @include tablet {
              position: absolute;
              bottom: 10px;
            }
          }
        }
      }
      .search {
        border-bottom: 1px solid gray;

        .searchForm {
          padding: 10px;

          input {
            background-color: transparent;
            border: none;
            color: white;
            outline: none;

            &::placeholder {
              color: lightgray;
            }
          }
        }
      }

      .userChat {
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: white;
        cursor: pointer;

        &:hover {
          background-color: #2f2d52;
        }

        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          object-fit: cover;
        }

        .userChatInfo {
          span {
            font-size: 18px;
            font-weight: 500;
          }
          p {
            font-size: 14px;
            color: lightgray;
          }
        }
      }
    }
    
  }
}

.modal-title {
  width: 100%;
  cursor: move;
  // position: sticky;
  z-index: 50;
}

.modal-content {
  // min-width: 700px;
  height: 0px;
  /* this is because we can resize this background so we'll hide it. The coloring will
  now be maintained by the modal-resizable class */
  background: transparent;
}

// .modal-content > .modal-resizable {
//   // min-width: 100px;
//   // height: 200px;
//   background-color: white;
//   background-clip: padding-box;
//   border: 1px solid #ebedee;
//   border: 0;
//   border-radius: .2em;
//   // top: 60px;
//   box-shadow: 0 0 0 1px rgba(101, 108, 126, 0.5) inset, 0 1px 3px 1px rgba(64, 64, 64, 0.5);
// }

// .modal-resizable > .modal-body {
//   /* This is to account for the height of the footer */
//   padding: 1em 1em 1em 1em;
//   min-width: 0px;
//   max-width: 600px;
//   height: 900px;
// }

// .modal-footer {
//   /* this is to keep the footer pinned to the bottom when we resize */
//   position: sticky;
//   bottom: 0;
//   width: 100%;
//   z-index: 10;
//   max-height: 100px;
// }


