@font-face {
  font-family: 'anticon';
  src: url('//at.alicdn.com/t/font_1434092639_4910953.eot');
  src: url('//at.alicdn.com/t/font_1434092639_4910953.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('//at.alicdn.com/t/font_1434092639_4910953.woff')
      format('woff'),
    /* chrome、firefox */ url('//at.alicdn.com/t/font_1434092639_4910953.ttf')
      format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('//at.alicdn.com/t/font_1434092639_4910953.svg#iconfont')
      format('svg'); /* IE9*/
  /* iOS 4.1- */
}
.rcicon {
  position: relative;
  display: inline-block;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0px;
  -moz-osx-font-smoothing: grayscale;
}
.rcicon:before {
  display: block;
  font-family: 'anticon' !important;
}
.rcicon-step-backward:before {
  content: '\e662';
}
.rcicon-step-forward {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
.rcicon-step-forward:before {
  content: '\e662';
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rcicon-fast-backward:before {
  content: '\e62a';
}
.rcicon-fast-forward {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
.rcicon-fast-forward:before {
  content: '\e62a';
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rcicon-shrink:before {
  content: '\e65f';
}
.rcicon-arrow-salt:before {
  content: '\e608';
}
.rcicon-caret-down:before {
  content: '\e60f';
}
.rcicon-caret-left {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}
.rcicon-caret-left:before {
  content: '\e60f';
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.rcicon-caret-up {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
.rcicon-caret-up:before {
  content: '\e60f';
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rcicon-caret-right {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.rcicon-caret-right:before {
  content: '\e60f';
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.rcicon-caret-circle-right:before {
  content: '\e60d';
}
.rcicon-caret-circle-left {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
.rcicon-caret-circle-left:before {
  content: '\e60d';
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rcicon-caret-circle-o-right:before {
  content: '\e60e';
}
.rcicon-caret-circle-o-left {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
.rcicon-caret-circle-o-left:before {
  content: '\e60e';
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rcicon-circle-right:before {
  content: '\e602';
}
.rcicon-circle-left {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
.rcicon-circle-left:before {
  content: '\e602';
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rcicon-circle-o-right:before {
  content: '\e603';
}
.rcicon-circle-o-left {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
.rcicon-circle-o-left:before {
  content: '\e603';
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rcicon-double-right:before {
  content: '\e604';
}
.rcicon-double-left {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
.rcicon-double-left:before {
  content: '\e604';
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rcicon-verticle-right:before {
  content: '\e605';
}
.rcicon-verticle-left {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
.rcicon-verticle-left:before {
  content: '\e605';
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rcicon-forward:before {
  content: '\e630';
}
.rcicon-backward {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
.rcicon-backward:before {
  content: '\e630';
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rcicon-rollback:before {
  content: '\e65a';
}
.rcicon-retweet:before {
  content: '\e659';
}
.rcicon-right:before {
  content: '\e611';
}
.rcicon-down {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}
.rcicon-down:before {
  content: '\e611';
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.rcicon-left {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
.rcicon-left:before {
  content: '\e611';
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rcicon-up {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.rcicon-up:before {
  content: '\e611';
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.rcicon-question:before {
  content: '\e655';
}
.rcicon-question-circle:before {
  content: '\e656';
}
.rcicon-question-circle-o:before {
  content: '\e657';
}
.rcicon-plus:before {
  content: '\e651';
}
.rcicon-plus-circle:before {
  content: '\e652';
}
.rcicon-plus-circle-o:before {
  content: '\e653';
}
.rcicon-pause:before {
  content: '\e64c';
}
.rcicon-pause-circle:before {
  content: '\e64d';
}
.rcicon-pause-circle-o:before {
  content: '\e64e';
}
.rcicon-minus:before {
  content: '\e646';
}
.rcicon-minus-circle:before {
  content: '\e647';
}
.rcicon-minus-circle-o:before {
  content: '\e648';
}
.rcicon-info-circle:before {
  content: '\e637';
}
.rcicon-info-circle-o:before {
  content: '\e638';
}
.rcicon-info:before {
  content: '\e63a';
}
.rcicon-exclamation:before {
  content: '\e627';
}
.rcicon-exclamation-circle:before {
  content: '\e628';
}
.rcicon-exclamation-circle-o:before {
  content: '\e629';
}
.rcicon-cross:before {
  content: '\e61e';
}
.rcicon-cross-circle:before {
  content: '\e61f';
}
.rcicon-cross-circle-o:before {
  content: '\e620';
}
.rcicon-check:before {
  content: '\e613';
}
.rcicon-check-circle:before {
  content: '\e614';
}
.rcicon-check-circle-o:before {
  content: '\e615';
}
.rcicon-clock-circle:before {
  content: '\e616';
}
.rcicon-clock-circle-o:before {
  content: '\e617';
}
.rcicon-lock:before {
  content: '\e641';
}
.rcicon-android:before {
  content: '\e601';
}
.rcicon-apple:before {
  content: '\e606';
}
.rcicon-area-chart:before {
  content: '\e607';
}
.rcicon-bar-chart:before {
  content: '\e609';
}
.rcicon-bars:before {
  content: '\e60a';
}
.rcicon-book:before {
  content: '\e60b';
}
.rcicon-calendar:before {
  content: '\e60c';
}
.rcicon-cloud:before {
  content: '\e618';
}
.rcicon-cloud-download:before {
  content: '\e619';
}
.rcicon-code:before {
  content: '\e61a';
}
.rcicon-copy:before {
  content: '\e61c';
}
.rcicon-credit-card:before {
  content: '\e61d';
}
.rcicon-delete:before {
  content: '\e621';
}
.rcicon-desktop:before {
  content: '\e622';
}
.rcicon-download-line:before {
  content: '\e623';
}
.rcicon-edit:before {
  content: '\e624';
}
.rcicon-ellipsis:before {
  content: '\e625';
}
.rcicon-environment:before {
  content: '\e626';
}
.rcicon-file:before {
  content: '\e62c';
}
.rcicon-file-text:before {
  content: '\e62d';
}
.rcicon-folder:before {
  content: '\e62e';
}
.rcicon-folder-open:before {
  content: '\e62f';
}
.rcicon-github:before {
  content: '\e631';
}
.rcicon-hdd:before {
  content: '\e632';
}
.rcicon-frown:before {
  content: '\e633';
}
.rcicon-meh:before {
  content: '\e634';
}
.rcicon-inbox:before {
  content: '\e635';
}
.rcicon-laptop:before {
  content: '\e63d';
}
.rcicon-large:before {
  content: '\e63e';
}
.rcicon-line-chart:before {
  content: '\e63f';
}
.rcicon-link:before {
  content: '\e640';
}
.rcicon-logout:before {
  content: '\e642';
}
.rcicon-mail:before {
  content: '\e643';
}
.rcicon-menu-fold:before {
  content: '\e644';
}
.rcicon-menu-unfold:before {
  content: '\e645';
}
.rcicon-mobile:before A {
  content: '\e649';
}
.rcicon-notification:before {
  content: '\e64a';
}
.rcicon-paper-clip:before {
  content: '\e64b';
}
.rcicon-picture:before {
  content: '\e64f';
}
.rcicon-pie-chart:before {
  content: '\e650';
}
.rcicon-poweroff:before {
  content: '\e654';
}
.rcicon-reload:before {
  content: '\e658';
}
.rcicon-search:before {
  content: '\e65b';
}
.rcicon-setting:before {
  content: '\e65c';
}
.rcicon-share-alt:before {
  content: '\e65d';
}
.rcicon-shopping-cart:before {
  content: '\e65e';
}
.rcicon-smile:before {
  content: '\e661';
}
.rcicon-tablet:before {
  content: '\e664';
}
.rcicon-tag:before {
  content: '\e665';
}
.rcicon-tags:before {
  content: '\e666';
}
.rcicon-to-top:before {
  content: '\e667';
}
.rcicon-unlock:before {
  content: '\e668';
}
.rcicon-upload:before {
  content: '\e669';
}
.rcicon-user:before {
  content: '\e66a';
}
.rcicon-video-camera:before {
  content: '\e66b';
}
.rcicon-windows:before {
  content: '\e66c';
}
.rcicon-loading:before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  -o-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
  content: '\e610';
}
:root .rcicon-step-forward,
:root .rcicon-fast-forward,
:root .rcicon-left,
:root .rcicon-up,
:root .rcicon-down,
:root .rcicon-caret-left,
:root .rcicon-caret-up,
:root .rcicon-caret-right,
:root .rcicon-caret-circle-left,
:root .rcicon-caret-circle-o-left,
:root .rcicon-circle-left,
:root .rcicon-circle-o-left,
:root .rcicon-double-left,
:root .rcicon-verticle-left,
:root .rcicon-backward {
  filter: none;
}
