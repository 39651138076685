.resize-handle {
  /* display: block; */
  /* position: absolute; */
  cursor: col-resize;
  width: 7px;
  /* right: 0; */
  /* top: 0; */
  /* z-index: 1; */
  border-right: 2px solid transparent;
  border-color: #ccc;
}

.resize-handle:hover {
  border-color: #ccc;
}

.resize-handle.active {
  border-color: #517ea5;
}
